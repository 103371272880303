.DoctorPage {
    width: 1200px;
    min-height: 400px;
    margin: 10px auto;
}
.DoctorPage img {
    width: 400px;
    height: 400px;
    object-fit: cover;
    object-position: top;
    float: left;
    margin-right: 10px;
}
.DoctorPage h1 {
    text-decoration: underline; 
    text-underline-offset: 10px; 
    font-family: kelson; 
}
.DoctorPage h3 {
    font-family: kelson; 
    font-weight: 700;
}
.DoctorPage h4 {
    font-family: kelson; 
    margin: 5px 0;
    text-decoration: underline;
    text-underline-offset: 5px;
}
.DoctorPage p {   
    text-align: justify;
}
.DoctorPage__bio__hours {

}
.DoctorPage__bio__hours__time {
    margin-top: 0px;
    font-weight: 500;
}
.DoctorPage__buttons-wrapper {
    width: 260px;
    margin: 0 auto;
}
.DoctorPage button {
    font-family: kelson;
    font-size: 14px;
    width: 120px;
    height: 30px;
    border: 0;
    border-radius: 5px;
    margin: 5px;
    background-color: rgb(0, 64, 255);
}
.DoctorPage__buttons-wrapper :nth-child(2) {
    background-color: hsl(191, 100%, 55%);
}
.DoctorPage button:hover {
    cursor: pointer;
    background-color: white;
    background-color: hsl(191, 100%, 35%);
    transition: background-color 0.5s;
}
.DoctorPage button a {
    text-decoration: none;
    color: white;
}

@media(max-width: 1300px) {
    .DoctorPage {
        width: 90%;
    }
    .DoctorPage img {
        width: 33%;
        height: 33%;
    }
    .DoctorPage__buttons-wrapper {

    }
}
@media(max-width: 920px) {
    .DoctorPage__bio__container {
        min-height: 100px;  
    }
}
@media(max-width: 680px) {
    .DoctorPage img {
        float: none;
        width: 400px;
        height: 400px;
        display: block;
        margin: 20px auto;
    }
    .DoctorPage__bio h1,
    .DoctorPage__bio h3,
    .DoctorPage__bio h4,
    .DoctorPage__bio .DoctorPage__bio__hours__time {
        text-align: center;
    }
    .DoctorPage__bio__container {
        min-height: 0;  
    }
}
@media(max-width: 450px) {
    .DoctorPage img {
        width: 100%;
        height: 100%;
    }
}
@media(max-width: 280px) {
    .DoctorPage__buttons-wrapper {
        width: 130px;
    }
}