.navbarBackground {
    position: absolute;
    width: 100%;
    height: 72px;
    right: 0;
    top: 0;
    z-index: -1;
    background-color: hsl(0, 0%, 90%);
}
.Clinics {
    width: 1450px;
    min-height: 85vh;
    margin: 90px auto 20px;
}
.Clinics h1 {
    font-family: kelson;
    text-decoration: underline;
    text-underline-offset: 10px;
    text-align: center;
}
#Clinics__doctors-select {
    display: block;
    margin: 10px auto;
    border: 1px solid black;
    border-radius: 15px;
    height: 30px;
    width: 120px;
    font-family: kelson;
    font-size: 15px;
    line-height: 30px;
    text-align: center;
    position: relative;
    z-index: 3;
}
.Clinics__background-bar {
    width: 800px;
    height: 60px;
    line-height: 56px;
    border-radius: 15px;
    position: relative;
    z-index: 2;
    display: block;
    margin: 0 auto;
    top: 55px;    
    background-color: hsl(0, 0%, 90%);
}
.Clinics__background-bar p {
    width: 150px;   
    padding-left: calc(50% - 220px);
}
.Clinics__windows-area {
    margin-top: 20px;
}

@media(max-width: 1460px) {
    .Clinics {
        width: 1080px;
    }
}
@media(max-width: 1110px) {
    .Clinics {
        width: 720px;
    }
    .Clinics__background-bar {
        margin-right: 0;
        width: 100%;
    }
}
@media(max-width: 740px) {
    .Clinics {
        width: 380px;
        margin-top: 180px;
    }
    .Clinics h1 {
        margin-bottom: 0;
        position: relative;
        top: 10px;
    }
    .Clinics__background-bar {
        height: 120px;
        top: 30px;
    }
    .Clinics__background-bar p {
        width: 100%;
        text-align: center;
    }
    #Clinics__doctors-select {
        position: relative;
        bottom: 30px;
    }
}
@media(max-width: 400px) {
    .Clinics {
        width: 80vw;
    }
}