.DoctorWindow {
    font-family: kelson;
    display: inline-block;
    height: 550px;
    width: 350px;
    margin: 5px;
}
.DoctorWindow img {
    height: 330px;
    width: 330px;
    border-radius: 5px;
    margin: 10px 10px;
    object-fit: cover;
    object-position: top;
}
.DoctorWindow div {
    text-align: center;
}
.DoctorWindow__name {
    font-size: 22px;
}
.DoctorWindow__spec {
    font-size: 18px;
    margin-top: 5px;
}
.DoctorWindow__desc {
    margin: 25px 10px;
}
.DoctorWindow .DoctorWindow__btn {
    margin-top: 15px;

    display: block;
    width: 80px;
    margin: 20px auto;
}
.DoctorWindow__btn button {
    font-family: kelson;
    font-size: 14px;
    text-decoration: none;    
    border-radius: 5px;
    border: 0;
    height: 30px;
    width: 100%;
    background-color: hsl(191, 100%, 55%);
    color: white;
}
.DoctorWindow__btn button:hover {
    cursor: pointer;
    background-color: white;
    background-color: hsl(191, 100%, 35%);
    transition: background-color 0.5s;
}
@media(max-width: 400px) {
    .DoctorWindow {
        width: 100%;
        margin: 0;
    }
    .DoctorWindow img {
        width: 96%;
        margin: 0 auto;
        display: block;
    }
}