.JoinUs {
    width: 800px;
    margin: 10px auto;
}
.JoinUs__wrapper {
    display: flex;
}
.JoinUs h1 {
    text-align: center;
    font-family: kelson;
    text-decoration: underline;
    text-underline-offset: 10px;
}
.JoinUs p {
    text-align: justify;
}
.JoinUs p span {
    font-weight: 700;
}
.JoinUs__photo {
    width: 500px;
    height: 300px;
    float: left;
}
.JoinUs__text {
    width: 630px;
    margin-left: 20px;
}
.JoinUs__text :nth-child(1) {
    text-indent: 40px;
}

@media (max-width: 820px) {
    .JoinUs {
        width: 100vw;

    }
    .JoinUs h1 {
        width: 100vw;
    }
    .JoinUs__wrapper {
        display: flex;
        flex-direction: column-reverse;
        width: 96vw;
    }
    .JoinUs__text {
        width: 92%;
        margin-left: 4%;
    }
    .JoinUs__text p {
        text-align: center;
    }
    .JoinUs__photo {
        float: none;
        max-width: 470px;
        max-height: 300px;
        width: 100vw;
        height: 64vw;
        margin: 10px auto;
    }
}

@media (max-width: 480px) {
    .JoinUs h1 {
        font-size: 25px;
    }
    .JoinUs__text p:nth-child(2) {
        width: 260px;
        margin: 0 auto 15px;
    }
}
@media (max-width: 480px) {
    .JoinUs h1 {
        font-size: 20px;
        text-decoration: none;
    }
}
@media (max-width: 280px) {
    .JoinUs__text p:nth-child(2) {
        width: 90%;
    }
}