.About {
    font-family: kelson;
    width: 800px;
    margin: 0 auto;
}
.About h1 {
    font-family: kelson;
    text-decoration: underline;
    text-underline-offset: 10px;
    text-align: center;
}
.About p {
    text-align: center;
    text-indent: 30px;
}

.About__btn {
    display: block;
    width: 100%;
    text-decoration: none;    
}
.About__btn button {
    font-family: kelson;
    font-size: 15px;
    letter-spacing: 0.05em;
    border-radius: 20px;
    border: 0;
    height: 40px;
    width: 150px;
    display: block;
    margin: 20px auto;
    background-color: hsl(191, 100%, 55%);
    color: white;
}
.About__btn button:hover {
    cursor: pointer;
    background-color: white;
    background-color: hsl(191, 100%, 35%);
    transition: background-color 0.5s;
}
.About p span {
    font-weight: 800;
}
.About__usg {
    margin-top: 40px;
    width: 800px;
    display: flex;
}
.About__usg img {
    width: 400px;
}
.About__usg p {
    padding-top: 30px;
    padding-left: 10px;
    text-align: left;
}
.About__license {
    margin-top: 40px;
    width: 800px;
    display: flex;
}
.About__license p {
    text-align: right;
    padding-top: 40px;
    padding-right: 15px;
    padding-left: 40px;
    width: 345px;
}
.About__license img {
    width: 400px;
}
.About__others {
    margin-top: 40px;
    margin-bottom: 40px;
    width: 800px;
    display: flex;
}
.About__others p {
    padding-top: 35px;
    padding-left: 15px;
    padding-right: 45px;
    text-align: left;
}
.About__others img {
    width: 400px;
}
.About img {
    border-radius: 15px;
}
.About__others p a {
    color: hsl(300, 100%, 25%);
    text-underline-offset: 2px;
}
.About__others p a:hover {
    transition: color 0.5s;
    color: hsl(0, 0%, 60%);
}

@media(max-width: 820px) {
    .About {
        width: 95%;
    }
    .About__license {
        width: 100%;
        display: flex;
        flex-direction: column;
    }
    .About__others,
    .About__usg {
        width: 100%;
        display: flex;
        flex-direction: column-reverse;
    }
    .About__license img,
    .About__others img,
    .About__usg  img {
        display: block;
        margin: 0 auto;
    }
    .About__license p,
    .About__others p {
        margin: 0 auto 30px;
        padding: 0;
        text-align: center;
    }
    .About__others p {
        width: 420px;
    }
    .About__usg  {
        margin-top: 10px;
    }
    .About__usg  p {
        padding: 0;
        margin: 0 auto 10px;
        text-align: left;
        width: 420px;
    }
    .About__usg div ul {
        margin-bottom: 30px;
        margin-left: calc(50% - 205px);
        width: 55%;
    }
}
@media(max-width: 450px) {
    .About__license img,
    .About__others img,
    .About__usg  img {
        width: 100%;
    }
    .About__usg  p {
        width: 300px;
    }
    .About__usg div ul {
        margin-left: 10%;
    }
    .About__license  p {
        width: 310px;
    }
    .About__others p {
        width: 293px;
    }
}