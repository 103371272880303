.Diagnostics {
    width: 730px;
    margin: 5px auto;
}
.Diagnostics h1 {
    font-family: kelson;
    text-decoration: underline;
    text-underline-offset: 10px;
    text-align: center;
}
.Diagnostics p {
    text-indent: 30px;
}
.Diagnostics__wrapper {
    width: 730px;
    margin: 10px auto;
}
.Diagnostics__wrapper img {
    height: 200px;
    width: 300px;
}
.Diagnostics__wrapper p {
    width: 390px;
    float: right;
    margin-right: 20px;
}
.Diagnostics__wrapper span {
    font-weight: 600;
}
.Diagnostics__wrapper p a {
    color: hsl(300, 100%, 25%);
    font-weight: 600;
    text-underline-offset: 2px;
}
.Diagnostics__wrapper p a:hover {
    transition: color 0.5s;
    color: hsl(0, 0%, 60%);
}
@media (max-width: 740px) {
    .Diagnostics {
        width: 90vw;
    }
    .Diagnostics p {
        text-align: center;
    }
    .Diagnostics__wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
    }
    .Diagnostics__wrapper p {
        width: 90%;
        text-align: center;
    }
    .Diagnostics__wrapper img {
        margin: 0 auto;
        max-width: 300px;
        width: 100%;
        height: 67%;
    }
}